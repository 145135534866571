import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    meta:{
      bigLogo: true ,
      showNav: true,
      showFooter: true
    }
  },
  {
    path: '/datenschutz',
    name: 'datenschutz', 
    component: () => import(/* webpackChunkName: "datenschutz" */ '../views/datenschutz-view.vue'),
    meta:{
      bigLogo: false,
      showNav: true,
      showFooter: true
    }
  },
  {
    path: '/impressum',
    name: 'impressum', 
    component: () => import(/* webpackChunkName: "impressum" */ '../views/impressum-view.vue'),
    meta:{
      bigLogo: false,
      showNav: true,
      showFooter: true
    }
  },
  {
    path: '/agb',
    name: 'agb', 
    component: () => import(/* webpackChunkName: "agb" */ '../views/agb-view.vue'),
    meta:{
      bigLogo: false,
      showNav: true,
      showFooter: true
    }
  },
  {
    path: '/redirect/:uid',
    name: 'redirect', 
    component: () => import(/* webpackChunkName: "redirect" */ '../views/redirect-view.vue'),
    meta:{
      bigLogo: false,
      showNav: true,
      showFooter: true
    }
  },
  {
    path: '/visitenkarte',
    name: 'visitenkarte', 
    component: () => import(/* webpackChunkName: "visitenkarte" */ '../views/visitenkarte-view.vue'),
    meta:{
      bigLogo: false,
      showNav: false,
      showFooter: true
    }
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
