import { Ref } from 'vue'
import { createStore } from 'vuex'

export class CustomRef {
  ref?: Ref
  scrollPosition?: string

  constructor(ref: Ref, scrollPosition = "center") {
    this.ref = ref
    this.scrollPosition = scrollPosition
  }
}

export default createStore({
  state: {
    navRefs: new Map<string, CustomRef>()
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
