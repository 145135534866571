import {initializeApp} from '@firebase/app';
import {connectStorageEmulator, getStorage} from "@firebase/storage";
import {getFirestore, connectFirestoreEmulator} from '@firebase/firestore';
import {getFunctions, connectFunctionsEmulator } from "@firebase/functions"
import {getAuth, connectAuthEmulator} from "@firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDWukOAi1wXRTzdMzMYtbrLY549gNBEQ-Q",
    authDomain: "db-itsolutions.firebaseapp.com",
    projectId: "db-itsolutions",
    storageBucket: "db-itsolutions.appspot.com",
    messagingSenderId: "572582117659",
    appId: "1:572582117659:web:7f0894d0564cdd34c353be",
    measurementId: "G-GMBHMNF3RH"
  };

const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp);

const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp, "europe-west3")

const auth = getAuth();
auth.languageCode = "de";

if(location.hostname == "localhost") {
    
    
    // connectFunctionsEmulator(functions, "localhost", 5001);
    // connectFirestoreEmulator(firestore, "localhost", 8081);
    /*
    connectStorageEmulator(storage, "localhost", 9199)
    connectAuthEmulator(auth, "http://localhost:9099");
    */
}

export {
    firestore,
    functions,
    auth,
    storage,
    firebaseApp
}